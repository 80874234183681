<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="审核状态:" placeholder="请选择审核状态">
                    <el-select v-model="filters.reviewStatus" clearable>
                        <el-option 
                            v-for="item in reviewStatusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row 
            @current-change="handlerowChange"
        >
            <el-table-column type='index' label='序号' width='60' :index='indexMethod'></el-table-column>
            <el-table-column label="批次名称" min-width="100" align="center" prop="ExamBatchPopulationName"></el-table-column>
            <el-table-column label="申请人" width="" align="center" prop="ApplyUserName"></el-table-column>
            <el-table-column label="考试地点" min-width="100" align="center" prop="ExamAddress"></el-table-column>
            <el-table-column  label="考试时间" min-width="100" align="center" prop="ExamTime"></el-table-column>
            <el-table-column  label="人数" width="" align="center" prop="Total">
                <template scope="scope">
                    <el-button type="text" @click="viewTotalNumFun(null,scope.row.ID)">{{scope.row.Total}}</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="发送短信成功人数" min-width="140" align="center" prop="SuccessNum">
                <template scope="scope">
                    <el-button type="text" @click="viewTotalNumFun(1,scope.row.ID)">{{scope.row.SuccessNum}}</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="发送短信失败人数" min-width="140" align="center" prop="FailNum">
                <template scope="scope">
                    <el-button type="text" @click="viewTotalNumFun(2,scope.row.ID)">{{scope.row.FailNum}}</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="审核人" width="" align="center" prop="ReviewUserName"></el-table-column>
            <el-table-column  label="审核状态" min-width="100" align="center" prop="ReviewStatus">
                <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.ReviewStatus == 1">审核通过</el-tag>
                    <el-tag :type="'warning'" v-else-if="scope.row.ReviewStatus == 2">审核驳回</el-tag>
                    <el-tag v-else>未审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column  label="备注" width="" align="center" prop="Remark"></el-table-column>
            <el-table-column label="创建时间" min-width="100" align="center" prop="CreateTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <el-pagination
                small
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                :current-page="pages.pageIndex"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange"
                class="pageBar"       
                >
            </el-pagination>
        </el-col>
        <!-- 申请组织考试 -->
        <el-dialog
            title="填写考试时间和地点"
            :visible.sync="examinationDialog"
            v-model="examinationDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="examinationForm" label-width="100px">
                <el-form-item label='备注:'>
                    <el-input placeholder="请输入备注" v-model='examinationForm.remark' type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="examinationDialog = false">取消</el-button>
                <el-button type="primary" @click.native="examinationSubmit" :loading="examinationLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import router from '../../router/index';
// 数据请求
import { getExamBatchMsgApplyRecordList,checkMsg, } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                reviewStatus: '',
            },
            reviewStatusArr: [
                {value: 0,name: "未审核"},
                {value: 1,name: "审核通过"},
                {value: 2,name: "审核驳回"},
            ],
            // 表格
            tableData: [],
            tableLoading: false,
            currentRow: '',
            // 分页
            pages:{
                pageArr:[20,40,80,100],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 审核驳回
            examinationDialog: false,
            examinationLoading: false,
            examinationForm: {
                remark: "",
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getExamApplyRecordList() {
            this.pages.pageIndex = 1
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            this.currentRow = ""
            var params = {
                reviewStatus: this.filters.reviewStatus,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.tableLoading = true
            getExamBatchMsgApplyRecordList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 审核通过
        passExamination() {
            if(this.currentRow) {
                if(this.currentRow.ReviewStatus == 1 || this.currentRow.ReviewStatus == 2) {
                    this.$message.warning("已通过/驳回数据无需重复审核！")
                    return
                }
                this.$confirm(`确定要审核通过？`,"提示",{
                }).then(() => {
                    var params = {
                        ReviewStatus: 1,
                        ApplyId: this.currentRow.ID,
                    }
                    checkMsg(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getTableDataFun()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
            } else {
                this.$message.warning("请选择要审核通过的数据！")
            }
        },
        // 审核驳回
        handleDel() {
            if(this.currentRow) {
                if(this.currentRow.ReviewStatus == 1 || this.currentRow.ReviewStatus == 2) {
                    this.$message.warning("已通过/驳回数据无需重复审核！")
                    return
                }
                this.examinationForm.remark = ""
                this.examinationDialog = true
                this.examinationLoading = false
            } else {
                this.$message.warning("请选择要审核驳回的数据！")
            }
        },
        // 驳回提交
        examinationSubmit() {
            this.$confirm(`确定要审核驳回？`,"提示",{
            }).then(() => {
                var params = {
                    ReviewStatus: 2,
                    ApplyId: this.currentRow.ID,
                    Remark: this.examinationForm.remark,
                }
                checkMsg(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message)
                        this.examinationDialog = false
                        this.getTableDataFun()
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.examinationLoading = false
                })
            })
        },
        // 查看人数，发送短信成功人数，发送短信失败人数
        viewTotalNumFun(val,ID) {
            router.replace({
                path: "/ApplyRecordDetails",
                query: {
                    redirect: val,
                    ID: ID,
                    time:new Date()
                }
            });
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        }, 
    },
    created(){
        this.getTableDataFun();
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>